// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-expertise-fintech-js": () => import("./../../../src/pages/expertise/fintech.js" /* webpackChunkName: "component---src-pages-expertise-fintech-js" */),
  "component---src-pages-expertise-government-js": () => import("./../../../src/pages/expertise/government.js" /* webpackChunkName: "component---src-pages-expertise-government-js" */),
  "component---src-pages-expertise-healthcare-js": () => import("./../../../src/pages/expertise/healthcare.js" /* webpackChunkName: "component---src-pages-expertise-healthcare-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

